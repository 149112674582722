/**
 * Note: Next.js will inline environment variables at build time that are passed to the front-end whether they have a
 *       `NEXT_PUBLIC` prefix or not. This creates a problem for DevOps because they use the same build/image across
 *       environments within the same domain. We don't want environment-specific values to be used on the wrong
 *       environment, so be wary of using `NEXT_PUBLIC` or passing secrets to components. If absolutely necessary, use
 *       `noStore` to prevent inlining secrets at build time.
 *
 * @see https://nextjs.org/docs/app/building-your-application/configuring/environment-variables
 * @see https://nextjs.org/docs/app/api-reference/functions/unstable_noStore
 * @since 0.1.0
 */

/**
 * --------------------------
 * API HTTP Status Codes
 * --------------------------
 */
export const API_RESULT_CREATED = "201 Created";

export const API_RESULT_UPDATED = "204 No Content";

export const API_RESULT_SUBMITTED = "205 Reset Content";

export const API_RESULT_CLIENT_ERROR = "400 Bad Request";

export const API_RESULT_SERVER_ERROR = "500 Internal Server Error";

/**
 * -------------
 * App
 * -------------
 */
export const APP_DEFAULT_BRAND = process.env.APP_DEFAULT_BRAND || "";

export const APP_DEFAULT_THEME = process.env.APP_DEFAULT_THEME || "";

export const APP_DOMAIN = process.env.NEXT_PUBLIC_APP_DOMAIN || "";

export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || "";

export const APP_ORIGIN = process.env.APP_ORIGIN || "";

export const AUTH_TOKEN = process.env.APP_AUTH_TOKEN;

export const APP_VERSION = process.env.npm_package_version || "";

export const DEBUG = process.env.NEXT_PUBLIC_APP_DEBUG === "true" || false;

export const DEFAULT_JOURNEY =
  process.env.NEXT_PUBLIC_APP_DEFAULT_JOURNEY || "";

export const DEVELOPMENT = "development";
export const LAB = "lab";
export const LOCAL = "local";
export const PRODUCTION = "production";
export const STAGING = "staging";
export const ENVIRONMENT = process.env.NEXT_PUBLIC_APP_ENV || PRODUCTION;

export const SECONDARY_OFFER_COOKIE_NAME = "secondary-offers-submission-id";

export const SECONDARY_OFFER_COOKIE_MAX_AGE = 86400; // 1 day is seconds

export const BENEFITS_SURVEY_COOKIE_NAME = "benefits-survey-submission-id";

export const BENEFITS_SURVEY_COOKIE_MAX_AGE = 86400; // 1 day is seconds

export const SECRET = "SECRET";

export const SITE_NAME = process.env.NEXT_PUBLIC_APP_SITE_NAME || "";

export const USER_COOKIE_MAX_AGE = 7776000; // 90 days in seconds

export const USER_COOKIE_NAME = "user";

export const VISIT_COOKIE_MAX_AGE = 86400; // 24 hours in seconds

export const VISIT_COOKIE_NAME = "visit";

/**
 * -------------
 * ButterCMS
 * -------------
 */
export const BUTTER_CMS = "ButterCMS";

export const BUTTER_CMS_API_KEY = process.env.BUTTER_CMS_API_KEY || "";

export const BUTTER_CMS_DEFAULT_DOMAIN = "billdoctor.org";

export const BUTTER_CMS_DOMAIN_PREFIX =
  process.env.BUTTER_CMS_DOMAIN_PREFIX || "";

export const BUTTER_CMS_POSTS_CATEGORY =
  process.env.BUTTER_CMS_POSTS_CATEGORY || "";

export const BUTTER_CMS_POSTS_LIMIT = 12;

/**
 * -------------
 * Braze
 * -------------
 */
export const BRAZE = "Braze";

export const BRAZE_API_KEY = process.env.NEXT_PUBLIC_BRAZE_API_KEY || "";

export const BRAZE_SDK_ENDPOINT =
  process.env.NEXT_PUBLIC_BRAZE_SDK_ENDPOINT || "";

/**
 * -------------
 * Maze
 * -------------
 */

export const MAZE_APPROVED_DOMAINS_AND_ENVIRONMENTS: Record<string, string[]> =
  {
    savewise: [PRODUCTION],
    "veteran-debt-assistance": [STAGING, LOCAL],
  };

/**
 * -------------
 * CookieBot
 * -------------
 */
export const COOKIEBOT = "Cookiebot";

export const COOKIEBOT_ID = process.env.NEXT_PUBLIC_COOKIEBOT_ID || "";

/**
 * -------------
 * Criteo
 * -------------
 */
export const CRITEO = "Criteo";

export const CRITEO_APP_ID = process.env.NEXT_PUBLIC_CRITEO_APP_ID || "";

/**
 * -------------
 * Datadog
 * -------------
 */
export const DATA_DOG_APP_ID = process.env.NEXT_PUBLIC_DATA_DOG_APP_ID || "";

export const DATA_DOG_CLIENT_TOKEN =
  process.env.NEXT_PUBLIC_DATA_DOG_CLIENT_TOKEN || "";

export const DATA_DOG_SERVICE_NAME =
  process.env.NEXT_PUBLIC_DATA_DOG_SERVICE_NAME || "";

/**
 * -------------
 * Events
 * -------------
 */
export const EVENT_BENEFITS_SURVEY_OUTCOME_CALCULATED =
  "benefitsSurveyOutcomeCalculated";

export const EVENT_COOKIE_CONSENT_VIEWED = "cookieConsentViewed";

export const EVENT_COOKIE_CONSENT_SUBMITTED = "cookieConsentSubmitted";

export const EVENT_ERROR = "error";

export const EVENT_DATA_LAYER_INITIALIZED = "dataLayerInitialized";

export const EVENT_DATA_LAYER_READY = "dataLayerReady";

export const EVENT_CTA_CLICKED = "CTAClicked";

export const EVENT_ELIGIBLE_BENEFIT_CLICKED = "eligibleBenefitClicked";

export const EVENT_JOURNEY_STARTED = "journeyStarted";

export const EVENT_JOURNEY_UPDATED = "journeyUpdated";

export const EVENT_LANDING_PAGE_VIEWED = "landingPageView";

export const EVENT_STEP_SUBMITTED = "stepSubmitted";

export const EVENT_STEP_VIEWED = "leadFormStepView";

export const EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS = "triggerNonEssentialScripts";

export const EVENT_LEAD_FORM_SUBMITTED = "leadFormSubmitted";

export const EVENT_NPS_5 = "NPS5";

export const EVENT_PAGE_NOT_FOUND = "pageNotFound";

export const EVENT_PAGE_VIEWED = "pageView";

export const EVENT_REDIRECT = "redirect";

export const EVENT_SECONDARY_OFFER_CLICKED = "secondaryOfferClicked";

export const EVENT_SECONDARY_OFFERS_FORM_SUBMITTED =
  "secondaryOffersFormSubmitted";

export const EVENT_SECONDARY_OFFERS_STEP_SUBMITTED =
  "secondaryOffersFormStepSubmitted";

export const EVENT_SURVEY_OUTCOME_CALCULATED = "surveyOutcomeCalculated";

export const EVENT_THANK_YOU_PAGE_VIEWED = "thankYouPageView";

/**
 * -------------
 * Facebook
 * -------------
 */
export const FACEBOOK = "Facebook";

export const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || "";

/**
 * -------------
 * Front-End API
 * -------------
 */
export const FRONT_END_API = "Front-End API";

export const FRONT_END_API_AUTH_TOKEN =
  process.env.FRONT_END_API_AUTH_TOKEN || "";

export const FRONT_END_API_BASE_URL = process.env.FRONT_END_API_BASE_URL || "";

/**
 * -------------
 * Google
 * -------------
 */
export const GOOGLE_TAG_MANAGER = "GTM";

export const GOOGLE_TAG_MANAGER_ID = process.env.GOOGLE_TAG_MANAGER_ID || "";

export const GOOGLE_TAG_MANAGER_AUTH =
  process.env.GOOGLE_TAG_MANAGER_AUTH || "";

export const GOOGLE_TAG_MANAGER_PREVIEW =
  process.env.GOOGLE_TAG_MANAGER_PREVIEW || "";

/**
 * -------------
 * Heap
 * -------------
 */
export const HEAP = "Heap";

export const HEAP_APP_ID = process.env.HEAP_APP_ID || "";

/**
 * -------------
 * Hotjar
 * -------------
 */
export const HOTJAR = "Hotjar";

export const HOTJAR_APP_ID = process.env.NEXT_PUBLIC_HOTJAR_APP_ID || "";

/**
 * -------------
 * Impact
 * -------------
 */
export const IMPACT_APP_ID = process.env.NEXT_PUBLIC_IMPACT_APP_ID || "";

/**
 * -------------
 * Land
 * -------------
 */
export const LAND = "Land";

export const LAND_DOMAIN_NAME = process.env.NEXT_PUBLIC_LAND_DOMAIN_NAME || "";

export const LAND_SITE_NAME = process.env.NEXT_PUBLIC_LAND_SITE_NAME || "";

/**
 * -------------
 * LaunchDarkly
 * -------------
 */
export const LAUNCHDARKLY = "LaunchDarkly";

export const LAUNCHDARKLY_CLIENT_SDK_KEY = "";

export const LAUNCHDARKLY_DOMAIN_PREFIX =
  process.env.LAUNCHDARKLY_DOMAIN_PREFIX || "";

export const LAUNCHDARKLY_SERVER_SDK_KEY =
  process.env.LAUNCHDARKLY_SERVER_SDK_KEY || "";

export const LAUNCHDARKLY_REDIRECT_QUERY_PARAM = "redirect";

/**
 * -------------
 * Lead Form
 * -------------
 */
export const DEFAULT_HERO_STEP = "debt-amount";

export const DEFAULT_LEAD_FORM_STEPS = [
  { name: "payment-situation", label: "" },
  { name: "student-loan-debt", label: "" },
  { name: "zip-code", label: "" },
  { name: "street-address", label: "" },
  { name: "city", label: "" },
  { name: "state", label: "" },
  { name: "dob", label: "" },
  { name: "name", label: "" },
  { name: "income-amount", label: "" },
  { name: "phone-number", label: "" },
  { name: "email", label: "" },
];

export const LEAD_FORM_CREATE = "create";

export const LEAD_FORM_FLOW = "lead-form";

export const LEAD_FORM_LOCAL_STORAGE_KEY = "lead-form";

export const LEAD_FORM_SUBMIT = "submit";

export const LEAD_FORM_UPDATE = "update";

/**
 * -------------
 * Neverbounce
 * -------------
 */

export const NEVERBOUNCE_PUBLIC_API_KEY =
  "public_eb5ffa121dedf888da18f8a2941d5765";

/**
 * -------------
 * Benefits Survey
 * -------------
 */
export const DEFAULT_BENEFITS_SURVEY_STEPS = [
  { name: "service", label: "" },
  { name: "discharge", label: "" },
  { name: "illness-and-injury", label: "" },
  { name: "september-11", label: "" },
  { name: "education-and-duty", label: "" },
  { name: "service-branch", label: "" },
  { name: "iadt", label: "" },
  { name: "disability", label: "" },
  { name: "va-service", label: "" },
  { name: "net-worth", label: "" },
  { name: "care", label: "" },
  { name: "wartime-vet", label: "" },
  { name: "housing", label: "" },
  { name: "sgli", label: "" },
  { name: "disability-rating", label: "" },
  { name: "age", label: "" },
];

export const BENEFITS_SURVEY_CREATE = "create";

export const BENEFITS_SURVEY_SUBMIT = "submit";

export const BENEFITS_SURVEY_UPDATE = "update";

export const SURVEY_CREATE = "create";
export const SURVEY_SUBMIT = "submit";
export const SURVEY_UPDATE = "update";

/**
 * -------------
 * Redis
 * -------------
 */
export const REDIS_URL = process.env.REDIS_URL || "redis://localhost:6379/0";

/**
 * -------------
 * Secondary Offers Form
 * -------------
 */
export const DEFAULT_SECONDARY_OFFERS_STEPS = [
  { name: "housing", label: "" },
  { name: "employment", label: "" },
  { name: "payment", label: "" },
  { name: "education", label: "" },
  { name: "amount", label: "" },
  { name: "ssn", label: "" },
];

export const SECONDARY_OFFERS_FORM_FLOW = "secondary-offers-form";

export const SECONDARY_OFFERS_FORM_CREATE = "create";

export const SECONDARY_OFFERS_FORM_SUBMIT = "submit";

export const SECONDARY_OFFERS_FORM_UPDATE = "update";

export const SUBMISSION_TYPE_EVEN_FINANCE_PREQUAL = "even_finance_prequal";

/**
 * -------------
 * TikTok
 * -------------
 */
export const TIKTOK_APP_ID = process.env.NEXT_PUBLIC_TIKTOK_APP_ID || "";

/**
 * -------------
 * Twilio
 * -------------
 */

export const TWILIO_ACCOUNT_SID = "AC1ac1113fa66ddc77529a5da473d25311";

export const TWILIO_AUTH_TOKEN = "fdffe29a4658e8223eb4a278de54530f";

/**
 * -------------
 * TrustPilot
 * -------------
 */

export const TRUST_PILOT_BUSINESS_UNIT_ID =
  process.env.NEXT_PUBLIC_TRUST_PILOT_BUSINESS_UNIT_ID || "";
export const TRUST_PILOT_URL = process.env.NEXT_PUBLIC_TRUST_PILOT_URL || "";

/**
 * -------------
 * URL Parameters
 * -------------
 */

export const URL_PARAM_GOOGLE_LOCATION_ID = "loc_physical_ms";

/**
 * -------------
 * Yup
 * -------------
 */

export const PHONE_NUMBER_ERROR_MESSAGE = "Please enter your phone number";
