"use client";

import { Fragment, useEffect, useState } from "react";

import Menu from "@client/components/site-header/components/menu";
import MenuItem from "@client/components/site-header/components/menu-item";
import Nav from "@client/components/site-header/components/nav";
import Toggle from "@client/components/site-header/components/toggle";

type Props = {
  baseUrl?: string;
  ctaUrl?: string;
};

export default function JourneyNavigation({
  baseUrl = "",
  ctaUrl = "",
}: Props) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isMenuHidden, setIsMenuHidden] = useState(true);
  const [isToggleHidden, setIsToggleHidden] = useState(false);

  useEffect(() => {
    const handleAriaHidden = () => {
      const $nav = document.getElementById("main-navigation");
      if ($nav) {
        setIsMenuHidden(getComputedStyle($nav).display === "none");
      }

      const $toggle = document.getElementById("main-navigation-toggle");
      if ($toggle) {
        setIsToggleHidden(getComputedStyle($toggle).display === "none");
      }
    };

    // run on 'init'
    handleAriaHidden();

    // run on 'resize'
    window.addEventListener("resize", handleAriaHidden);

    // clear 'resize' event listener
    return () => {
      window.removeEventListener("resize", handleAriaHidden);
    };
  }, [isNavExpanded]);

  return (
    <Nav>
      <Menu ariaLabel="Main menu" depth={0}>
        {[
          <MenuItem
            href={ctaUrl}
            label="Get Started"
            variant="button"
            key="get-started"
          />,
          <Fragment key="secondary-menu">
            <Toggle
              ariaControls="hamburger-menu"
              isAriaHidden={isToggleHidden}
              isNavExpanded={isNavExpanded}
              onClick={() => setIsNavExpanded(!isNavExpanded)}
            />
            <Menu
              ariaLabel="Secondary menu"
              depth={1}
              id="hamburger-menu"
              isAriaHidden={isMenuHidden}
              isNavExpanded={isNavExpanded}
              onClick={() => setIsNavExpanded(false)}
            >
              <MenuItem href={`${baseUrl}/about`} label="About" key="about" />
              <MenuItem
                href={`${baseUrl}/resources`}
                label="Resources"
                key="resources"
              />
              <MenuItem
                href={ctaUrl}
                label="Get Started"
                variant="button"
                key="get-started"
              />
            </Menu>
          </Fragment>,
        ]}
      </Menu>
    </Nav>
  );
}
